import React, { useContext, useEffect, useState } from 'react';
import Page from 'src/components/Page';

import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useNavigate } from 'react-router-dom';
import {Badge, Card, Chip, Container, Grid, Link, Stack, Typography, styled} from '@mui/material';
import CustomSpinner from "./CustomSpinner";
import { getConversation, getConversationUnread } from '../service/conversationApi';
import { ProductImgStyle } from 'src/service/componentService';
import AuthContext from 'src/contexts/AuthContext';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Page>
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    </Page>
  );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

export default function CustomPaginationActionsTable() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [currentConvs, setCurrentConvs] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const { customerId } = useContext(AuthContext)

    const navigate = useNavigate();

    useEffect(() => {
        fetchConversations();
    }, []);

    const fetchConversations = async () => {
        try {
            setIsLoading(true)
            if (!customerId) {
                setIsLoading(false)
                return
            }
            //let data = await getConversation(`?annonce.customer=${customerId}`)
            let data = await getConversationUnread(21);
            
            /*data = data.filter((conv) => {
                if (conv.annonce.annonce.status === "RESTITUÉ" || conv.annonce.annonce.status === "ENVOYÉ") {
                    return false
                }
                return true
            })*/

            setRows(data);
            //let notResponded = data.filter((conv) => conv.isPending === true);
            setCurrentConvs(data);
            setIsLoading(false)

        } catch (error) {
            console.error(error);
        }
    };

    const getNbrUnreadMessages = (messages) => {
        let filtered = messages.filter((message) => message.customerAuthor === null);
        let unread = filtered.filter((message) => message.isRead === false);
        return unread.length;
    };

  const handleGoToAds = (id) => {
    navigate(`/dashboard/products/${id}`);
  };

    return (
        <Container>
        {isLoading ? (
            <>
                <CustomSpinner />
            </>
        ) : (
            <>
                <Grid container spacing={3} sx={{justifyContent: "center", marginTop: "40px"}}>
                    {currentConvs.map((conv) => (
                        <Grid
                            item xs={12} sm={6} md={4}
                            sx={{cursor: "pointer", position: "relative"}}
                        >
                                <Card
                                    key={conv.annonce.id}
                                    onClick={() => {
                                        handleGoToAds(conv.annonce.id);
                                    }}
                                    sx={{ width: '100%' }}
                                >
                                    <Box sx={{ pt: '100%', position: 'relative'}}>
                                        {conv.annonce.filePath ? (
                                          <ProductImgStyle src={`${process.env.REACT_APP_PUBLIC_URL}/images/annonces/${conv.annonce.filePath}`} />
                                        ) : (
                                          <ProductImgStyle src={`${process.env.REACT_APP_PUBLIC_URL}/images/annonces/default/${conv.annonce.category.defaultImage}`} />
                                        )}
                                    </Box>
                                    <Stack spacing={2} sx={{ p: 3 }}>
                                        <Link to="#" color="inherit" underline="hover">
                                            <Typography sx={{ display: 'flex', justifyContent: 'space-between' }} variant="subtitle" noWrap>
                                                <Typography variant="h5"> {conv.annonce.category.name}</Typography>
                                                <Chip label={conv.annonce.id} color="primary" />
                                            </Typography>
                                        </Link>
                                    </Stack>

                                </Card>
                        </Grid>
                    ))}
                  </Grid>
            </>
        )}
    </Container>
  );
}


                            /*<Badge badgeContent={getNbrUnreadMessages(conv.annonce.annonce.messages)} color="error"
                                sx={{right: "0", position: "absolute"}}
                            >
                            </Badge>*/